import { gql } from '@apollo/client';

import {
	BEST_IN_TEAM_SEASON_PAGE_FRAGMENT,
	BEST_TEAMS_IN_SEASON_FRAGMENT,
	LOCALIZED_SB_FRAGMENT,
	LOCALIZED_STRING_FRAGMENT,
	MULTI_LANG_STRING,
	PERSON_CAREER_INFO,
	RANKING_PLAYER_STAT_FRAGMENT,
	RANKING_TEAM_STAT_FRAGMENT,
	STRUCTURED_BODY_UNIFICATION,
	TAG_STAT_MATCH_FRAGMENT,
} from '@store/fragments';
import { POLL_DESCRIPTION_FRAGMENT } from '@store/poll/queries';

const TAG_PERSON_EXTRA_FRAGMENT = gql`
	${LOCALIZED_STRING_FRAGMENT}
	fragment tagPersonExtra on TagPersonExtra {
		firstName {
			...localizedString
		}
		lastName {
			...localizedString
		}
		amplua {
			id
			name {
				...localizedString
			}
		}
		role {
			id
			name {
				...localizedString
			}
		}
		citizenship {
			picture(productType: TRIBUNA, format: PNG) {
				main
				resize(width: "80px", height: "80px")
			}
			name
		}
		weight
		height
		dateBirth
		dateDeath
		sex
	}
`;

const TAG_TEAM_EXTRA_FRAGMENT = gql`
	${LOCALIZED_STRING_FRAGMENT}
	fragment tagTeamExtra on TagTeamExtra {
		interfaceTitle {
			...localizedString
		}
		country {
			name
			picture(productType: TRIBUNA, format: PNG) {
				main
			}
		}
		city {
			...localizedString
		}
		type
	}
`;

const TAG_TOURNAMENT_EXTRA_FRAGMENT = gql`
	${LOCALIZED_STRING_FRAGMENT}
	fragment tagTournamentExtra on TagTournamentExtra {
		interfaceTitle {
			...localizedString
		}
		dateStart
		dateEnd
		winner {
			...localizedString
		}
		country {
			name
		}
	}
`;

const TAG_TOURNAMENT_MAIN_INFO_STAT_OBJECT = gql`
	${TAG_TEAM_EXTRA_FRAGMENT}
	${LOCALIZED_STRING_FRAGMENT}
	fragment tagTournamentMainStatObject on statTournament {
		id
		name
		logo {
			main: resize(width: "120", height: "120")
		}
		isFriendly
		competitionFormat
		country {
			name
			code
		}
		area {
			name
		}
		champion {
			id
			url
			name
			tag {
				id
				title {
					...localizedString
				}
				extra {
					...tagTeamExtra
				}
			}
		}
		currentSeason {
			name
			id
			shortName
			endDate
			startDate
			tours {
				number
				startDate
				endDate
			}
			stages {
				type
			}
			participants {
				id
			}
		}
	}
`;

const TAG_CLUB_MAIN_INFO_STAT_OBJECT_WITHOUT_ROSTER = gql`
	${LOCALIZED_STRING_FRAGMENT}
	fragment tagClubMainInfoStatObjectWithoutRoster on statTeam {
		id
		name
		logo {
			main
		}
		lastFive {
			barHeight
			result
			match {
				id
				hru
				url
			}
		}
		city
		type
		captain {
			lastName
			url
		}
		country {
			picture(productType: TRIBUNA, format: PNG) {
				main
			}
			name
		}
		venue {
			name
			country {
				name
			}
			url
			capacity
		}
		tag {
			hru
		}
		homeTournament {
			url
			name
			currentSeason {
				id
				shortName
			}
			tag {
				title {
					...localizedString
				}
				hru
			}
		}
	}
`;

const TAG_CLUB_MAIN_INFO_STAT_OBJECT = gql`
	${TAG_CLUB_MAIN_INFO_STAT_OBJECT_WITHOUT_ROSTER}
	fragment tagClubMainInfoStatObject on statTeam {
		...tagClubMainInfoStatObjectWithoutRoster
		roster(occupation: MANAGER) {
			role
			career {
				player {
					lastName
					url
					id
					name
					firstName
				}
			}
		}
	}
`;

const TAG_FRAGMENT = gql`
	${LOCALIZED_STRING_FRAGMENT}
	${LOCALIZED_SB_FRAGMENT}
	${TAG_PERSON_EXTRA_FRAGMENT}
	${TAG_TEAM_EXTRA_FRAGMENT}
	${TAG_TOURNAMENT_EXTRA_FRAGMENT}
	${MULTI_LANG_STRING}
	fragment Tag on Tag {
		id
		type
		subtitle {
			...localizedString
		}
		hru
		title {
			...localizedString
		}
		hruList
		logo {
			url
			webp
		}
		seo {
			name {
				...localizedString
			}
			h1 {
				...localizedString
			}
			title {
				...localizedString
			}
			description {
				...localizedString
			}
			synopsis {
				...localizedString
			}
			canonical {
				...localizedString
			}
			synonyms {
				...localizedString
			}
			faqItems {
				title {
					...multiLangString
				}
				structuredBody {
					...LocalizedSB
				}
			}
			text {
				...LocalizedSB
			}
		}
		seoPages {
			type
			seo {
				h1 {
					...localizedString
				}
				title {
					...localizedString
				}
				description {
					...localizedString
				}
				canonical {
					...localizedString
				}
				synopsis {
					...localizedString
				}
				faqItems {
					title {
						...multiLangString
					}
					structuredBody {
						...LocalizedSB
					}
				}
				text {
					...LocalizedSB
				}
			}
		}
		sport {
			id
			path
			name {
				...localizedString
			}
			adjectives {
				m {
					...localizedString
				}
			}
			athleteTitle {
				m {
					...localizedString
				}
				f {
					...localizedString
				}
			}
		}
		extra {
			...tagPersonExtra
			...tagTeamExtra
			...tagTournamentExtra
		}
	}
`;

const LIGHT_STAT_MATCH_FRAGMENT = gql`
	fragment statMatchLight on statMatch {
		id
		bettingOdds {
			hxa {
				h
				x
				a
			}
			bookmaker {
				id
				name
			}
		}
		matchStatus
		periodId
		scheduledAt
		currentMinute
		currentMinuteMain
		currentMinuteOver
		url
		dateOnly
		roundName
		hadPenalties
		home {
			team {
				id
				name
				url
				tag {
					hru
					id
				}
				logo {
					webp(width: "60", height: "60", quality: 100)
				}
			}
			score
			penaltyScore
		}
		away {
			team {
				id
				name
				url
				tag {
					hru
					id
				}
				logo {
					webp(width: "60", height: "60", quality: 100)
				}
			}
			score
			penaltyScore
		}
		winner
		winnerTeam {
			team {
				id
			}
		}
		venue {
			name
		}
		season {
			shortName
			tournament {
				name
				url
			}
		}
	}
`;

const BEST_IN_TEAM_SEASON_WIDGET_FRAGMENT = gql`
	${RANKING_PLAYER_STAT_FRAGMENT}
	fragment BestInSeasonWidgetFragment on statSeason {
		shortName
		rankingPlayerStat(
			input: {
				teams: $statTeamID
				limit: 3
				attribute: [
					TOTAL_GOALS
					TOTAL_ASSISTS
					TOTAL_DUELS_WON
					TOTAL_ATT_ASSIST
				]
			}
		) {
			...RankingPlayerStatFragment
		}
		goalkeeperPlayerStat: rankingPlayerStat(
			input: {
				teams: $statTeamID
				limit: 3
				attribute: [TOTAL_CLEAN_SHEET]
				amplua: [GOALKEEPER]
			}
		) {
			...RankingPlayerStatFragment
		}
	}
`;

const STAT_CURRENT_SEASON_BEST_PLAYERS = gql`
	${RANKING_PLAYER_STAT_FRAGMENT}
	fragment statCurrentSeasonBestPlayers on statSeason {
		rankingPlayerStat(
			input: {
				limit: 3
				attribute: [
					TOTAL_GOALS
					TOTAL_ASSISTS
					TOTAL_DUELS_WON
					TOTAL_ATT_ASSIST
				]
			}
		) {
			...RankingPlayerStatFragment
		}
		goalkeeperPlayerStat: rankingPlayerStat(
			input: { limit: 3, attribute: [TOTAL_CLEAN_SHEET], amplua: [GOALKEEPER] }
		) {
			...RankingPlayerStatFragment
		}
	}
`;

const STANDINGS_FRAGMENT = gql`
	fragment standingsFragment on statTeamStandingLine {
		team {
			id
			name
			url
			coverage {
				matches
			}
			logo {
				main
				resize(width: "32px", height: "32px")
			}
			lastFive: lastFiveSeason(season: $season) {
				match {
					id
					hru
					url
				}
				pointsDiff
				barHeight
				result
			}
		}
		current_outcome
		rank
		rankChange
		groupName
		played
		goalsFor
		goalsAgainst
		goalDiff
		win
		loss
		draw
		points
	}
`;

const PAIR_TEAM_FRAGMENT = gql`
	fragment pairTeam on statTeamLite {
		id
		name
		logo {
			url: main
			main
		}
	}
`;

const PERSON_MAIN_INFO_FRAGMENT = gql`
	${TAG_FRAGMENT}
	${LOCALIZED_STRING_FRAGMENT}
	fragment personMainInfoFragment on Tag {
		...Tag
		tabs(lang: $locale)
		statObject {
			... on statPlayer {
				fieldPositionName
				id
				name
				url
				firstName
				lastName
				fieldPosition
				type
				dateOfBirth
				dateOfDeath
				nationality {
					name
				}
				avatar {
					resize(width: "80px", height: "80px")
				}
				height
				weight
				gender
				preferredFoot
				placeOfBirth
				currentClub {
					id
					tag {
						id
						hru
						title {
							...localizedString
						}
						extra {
							...tagTeamExtra
						}
						sport {
							path
						}
					}
					name
					logo {
						resize(width: "80px", height: "80px")
					}
					homeTournament {
						currentSeason {
							id
							shortName
						}
						nearestSeason {
							teamSeasonName
							shortName
						}
					}
					url
				}
				currentNational {
					name
					url
					tag {
						hru
						title {
							...localizedString
						}
						extra {
							... on TagTeamExtra {
								type
							}
						}
					}
				}
			}
		}
	}
`;

const PLAYER_STAT_MATCH_DATA_FRAGMENT = gql`
	fragment playerStatMatchData_info on statMatch {
		id
		hru
		scheduledAt
		hadPenalties
		matchStatus
		coverageLevel
		url
		home {
			score
			penaltyScore
			team {
				name
				logo {
					webp(width: "40", height: "40", quality: 80)
				}
			}
		}
		away {
			score
			penaltyScore
			team {
				name
				logo {
					webp(width: "40", height: "40", quality: 80)
				}
			}
		}
	}
`;

const STRUCTURED_BODY = gql`
	fragment StructuredBodyFragment on BodyTopElement {
		type
		value {
			... on BodyParagraph {
				platforms
				elements {
					type
					value {
						... on BodyText {
							text
							format {
								bold
								italic
								color
							}
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
								color
							}
						}
					}
				}
			}
			... on BodySubtitle {
				platforms
				text
				level
				elements {
					type
					value {
						... on BodyText {
							text
							format {
								bold
								italic
							}
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
							}
						}
					}
				}
			}
			... on BodyLinkedImage {
				href
				image {
					source
					width
					height
					text
				}
			}
			... on BodyImage {
				source
				width
				height
				text
			}
			... on BodyTwitter {
				url
				id
			}
			... on BodyInstagram {
				url
				id
			}
			... on BodyIframe {
				source
				width
				height
			}
			... on BodyYoutube {
				source
				id
				start
				end
			}
			... on BodyAd {
				size
				name
				platforms
			}
			... on BodyList {
				style
				items {
					elements {
						type
						value {
							... on BodyText {
								text
								format {
									bold
									italic
									color
								}
							}
							... on BodyLink {
								text
								href
								format {
									bold
									italic
									color
								}
							}
						}
					}
				}
			}
		}
	}
`;

const CUSTOM_TAB_FRAGMENT = gql`
	${STRUCTURED_BODY}
	fragment CustomTab on CustomTab {
		id
		name
		hru
		redirect
		availableLanguages
		seo {
			h1
			title
			description
			canonical
			synopsis
			faqItems {
				title
				structuredBody {
					...StructuredBodyFragment
				}
			}
			text {
				...StructuredBodyFragment
			}
		}
	}
`;

const AUTHOR_FRAGMENT = gql`
	fragment Author on Author {
		user {
			id
			name
			nickname
			avatar {
				url
			}
		}
	}
`;

const POST_FRAGMENT = gql`
	${AUTHOR_FRAGMENT}
	fragment Post on Post {
		id
		hru
		title {
			defaultValue(language: $lang)
		}
		mainPhoto {
			url
		}
		publicationTime
		commentsCount
		link
		reactions {
			userReaction {
				objectClass
				objectID
				reactionType
			}
			likeCount
			dislikeCount
		}
		mainSport {
			path
			id
		}
		author {
			...Author
		}
		displayAuthor {
			...Author
		}
		status
		label
		blog {
			id
			hru
			title
			avatar {
				url
			}
			founder {
				id
				name
				nickname
			}
		}
		structuredDescription {
			defaultValue(language: $lang) {
				value {
					... on BodyParagraph {
						elements {
							value {
								... on BodyText {
									text
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default {
	getTagMainInfo: gql`
		${TAG_FRAGMENT}
		${TAG_STAT_MATCH_FRAGMENT}
		${MULTI_LANG_STRING}
		query getTagMainInfo($hru: String!, $locale: Language) {
			tagQueries {
				get(input: { id: $hru, idType: HRU }) {
					...Tag
					tabs
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTeam {
							id
							name
							lastFive {
								result
								barHeight
							}
							logo {
								main
							}
							teaser(last: 1, next: 4) {
								last {
									...statMatchForTag
								}
								next {
									...statMatchForTag
								}
								current {
									...statMatchForTag
								}
							}
							tag {
								hru
							}
							homeTournament {
								currentSeason {
									shortName
								}
							}
						}
						... on statPlayer {
							id
							name
							type
							firstName
							lastName
							coalesceName(names: ["lineupName", "lastName", "name"])
							fieldPosition
							currentClub {
								name
							}
							dateOfBirth
							dateOfDeath
							nationality {
								name
							}
							countryOfBirth {
								name
							}
							placeOfBirth
							careers {
								team {
									id
								}
								jerseyNumber
							}
							avatar {
								main
							}
							fieldPosition
							height
							weight
							preferredFoot
							gender
							lastMatches {
								match {
									...statMatchForTag
								}
							}
							currentClub {
								id
								tag {
									hru
								}
								name
								type
								url
								logo {
									main
									resize(width: "80px", height: "80px")
								}
								homeTournament {
									id
									name
									logo {
										main
									}
									tag {
										hru
									}
									currentSeason {
										shortName
									}
								}
								teaser(last: 3, next: 3) {
									next {
										...statMatchForTag
									}
								}
								currentTournaments {
									name
									tag {
										hru
									}
								}
							}
							stat {
								matchesPlayed
								goalsScored
								goalsConceded
								cleanSheet
								assists
								yellowCards
								redCards
								season {
									shortName
								}
								team {
									id
								}
							}
						}
						... on statTournament {
							id
							name
							logo {
								main
							}
							country {
								name
							}
						}
					}
					seo {
						faqItems {
							title {
								...multiLangString
							}
							structuredBody {
								...LocalizedSB
							}
						}
						text {
							...LocalizedSB
						}
					}
					seoPages {
						type
						seo {
							faqItems {
								title {
									...multiLangString
								}
								structuredBody {
									...LocalizedSB
								}
							}
							text {
								...LocalizedSB
							}
						}
					}
				}
			}
		}
	`,
	getTagSeoInfo: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_SB_FRAGMENT}
		${MULTI_LANG_STRING}
		${STRUCTURED_BODY_UNIFICATION}
		query getTagSeoInfo(
			$id: String!
			$tagIdType: TagIdType!
			$language: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					hru
					seo {
						h1 {
							...localizedString
						}
						title {
							...localizedString
						}
						description {
							...localizedString
						}
						canonical {
							...localizedString
						}
						faqItems {
							title {
								...multiLangString
							}
							structuredBody {
								...LocalizedSB
							}
						}
						text {
							...LocalizedSB
						}
					}
					seoPages {
						type
						seo {
							h1 {
								...localizedString
							}
							title {
								...localizedString
							}
							description {
								...localizedString
							}
							canonical {
								...localizedString
							}
							faqItems {
								title {
									...multiLangString
								}
								structuredBody {
									...LocalizedSB
								}
							}
							text {
								defaultValue(language: $language) {
									...StructuredBodyFragmentUnification
								}
							}
						}
					}
				}
			}
		}
	`,
	getTagsList: gql`
		${LOCALIZED_STRING_FRAGMENT}
		query getTagsList(
			$page: Int = 1
			$pageSize: Int = 50
			$sport: SportID = FOOTBALL
			$tagType: TagType
			$tagTeamType: TagTeamType
			$tagSubGroup: TagSubGroup
		) {
			tagQueries {
				feed(
					input: {
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						filter: {
							sport: $sport
							tagType: $tagType
							hasTabs: true
							filterByTagType: { team: { type: $tagTeamType } }
							filterByTagSubGroup: $tagSubGroup
						}
					}
				) {
					items {
						id
						hru
						title {
							...localizedString
						}
						type
						logo {
							url
							webp
						}
						sport {
							id
							path
							name {
								...localizedString
							}
						}
						contentCount
						extra {
							... on TagTeamExtra {
								type
							}
							... on TagPersonExtra {
								role {
									id
								}
							}
						}
					}
					paginationInfo {
						... on PaginationByPage {
							totalItemsCount
							lastPage
						}
					}
				}
			}
		}
	`,
	getTournamentTransfersPageInfo: gql`
		${TAG_FRAGMENT}
		${TAG_TOURNAMENT_MAIN_INFO_STAT_OBJECT}
		query getTournamentTransfersPageInfo(
			$id: String!
			$locale: Language!
			$tagIdType: TagIdType!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					statObject {
						... on statTournament {
							...tagTournamentMainStatObject
							transferWindows {
								year
								window
							}
						}
					}
				}
			}
		}
	`,

	getMatchCenterData: gql`
		fragment teamData on statTeamMatch {
			team {
				id
				name
				picture(productType: TRIBUNA, format: LOGO) {
					webp(height: "40", width: "40", quality: 100)
				}
			}
			score
			penaltyScore
		}

		fragment matchData on statMatch {
			matchStatus
			currentMinute
			currentMinuteMain
			currentMinuteOver
			scheduledAt
			periodId
			id
			url
			hadPenalties
			home {
				...teamData
			}
			away {
				...teamData
			}
		}

		query matchCenterQuery($date: String!, $status: [matchStatus!]) {
			stat {
				football {
					stat_matchesByDate(filter: { date: $date, status: $status }) {
						liveMatchesCount
						matchesCount
						sortedTournaments {
							tournament {
								name
								url
								id
								picture(productType: TRIBUNA, format: LOGO) {
									webp(height: "40", width: "40", quality: 100)
								}
							}
							matches {
								...matchData
							}
						}
					}
				}
			}
		}
	`,
	getTournamentStandings: gql`
		${TAG_FRAGMENT}
		${TAG_TOURNAMENT_MAIN_INFO_STAT_OBJECT}
		fragment season on statSeason {
			id
			name
			shortName
			tournament {
				id
				name
				tag {
					hru
					id
				}
			}
		}
		query getTournamentStandings(
			$idForStat: ID!
			$id: String!
			$source: statSourceList
			$locale: Language!
			$tagIdType: TagIdType!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTournament {
							...tagTournamentMainStatObject
						}
					}
				}
			}
			stat {
				football {
					stat_tournament(id: $idForStat, source: $source) {
						id
						name
						logo {
							main
						}
						currentSeason {
							...season
						}
						seasons {
							name
							year
							...season
						}
						seasonsWithTable {
							name
							year
							...season
						}
						type
					}
				}
			}
		}
	`,
	getTournamentMainInfo: gql`
		${TAG_FRAGMENT}
		${TAG_TOURNAMENT_MAIN_INFO_STAT_OBJECT}
		query getTournamentMainInfo(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTournament {
							...tagTournamentMainStatObject
						}
					}
				}
			}
		}
	`,
	getTournamentMainInfoMainPage: gql`
		${TAG_FRAGMENT}
		${LIGHT_STAT_MATCH_FRAGMENT}
		${TAG_TOURNAMENT_MAIN_INFO_STAT_OBJECT}
		${STAT_CURRENT_SEASON_BEST_PLAYERS}
		query getTournamentMainInfoMainPage(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTournament {
							...tagTournamentMainStatObject
							competitionType
							currentSeason {
								participantsNew {
									id
									name
									url
								}
								mostSpaciousVenue {
									id
									name
									url
									capacity
									tag {
										extra {
											... on TagOtherExtra {
												interfaceTitle {
													string(language: $locale)
												}
											}
										}
										title {
											string(language: $locale)
										}
									}
								}
								teaser(next: 2, last: 2) {
									next {
										roundName
										...statMatchLight
									}
									current {
										roundName
										...statMatchLight
									}
									last {
										roundName
										...statMatchLight
									}
								}
								...statCurrentSeasonBestPlayers
								rounds {
									teamStanding {
										total(limit: 4) {
											groupName
											team {
												id
												name
												url
												logo {
													main
													resize(width: "32px", height: "32px")
												}
											}
											lastFiveSeason {
												match {
													url
													id
												}
												result
											}
											rank
											played
											points
											win
											draw
											loss
											goalsFor
											goalsAgainst
											goalDiff
										}
									}
								}
							}
							promotion {
								id
								name
								url
							}
							relegation {
								id
								name
								url
							}
							topTeams(limit: 5) {
								team {
									id
									name
									url
								}
								wins
							}
						}
					}
				}
				recommendationList {
					tournaments {
						statObject {
							... on statTournament {
								url
								name
								url
							}
						}
					}
				}
			}
		}
	`,

	getTournamentMatchesFull: gql`
		${TAG_FRAGMENT}
		${TAG_TOURNAMENT_MAIN_INFO_STAT_OBJECT}
		query getTournamentMatchesFull(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTournament {
							...tagTournamentMainStatObject
							seasonsFixtures {
								id
								name
								shortName
								startDate
								endDate
								rounds {
									tours {
										startDate
										endDate
										number
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getSeasonWithoutBrackets: gql`
		${STANDINGS_FRAGMENT}
		query getSeasonWithoutBrackets($id: [String!]!, $season: ID!) {
			stat {
				football {
					stat_season(id: $id) {
						id
						name
						shortName
						tournament {
							id
							name
							tag {
								id
								hru
							}
						}
						standingsBracketsStages {
							... on statTRound {
								includeStandings
								name
								active
								teamStanding {
									total {
										...standingsFragment
									}
									home {
										team {
											id
											name
											url
											coverage {
												matches
											}
											logo {
												main
												resize(width: "32px", height: "32px")
											}
											lastFive: lastFiveSeason(
												season: $season
												qualifier: HOME
											) {
												match {
													id
													url
												}
												pointsDiff
												barHeight
												result
											}
										}
										current_outcome
										rank
										rankChange
										groupName
										played
										goalsFor
										goalsAgainst
										goalDiff
										win
										loss
										draw
										points
									}
									away {
										team {
											id
											name
											url
											coverage {
												matches
											}
											logo {
												main
												resize(width: "32px", height: "32px")
											}
											lastFive: lastFiveSeason(
												season: $season
												qualifier: AWAY
											) {
												match {
													id
													url
												}
												pointsDiff
												barHeight
												result
											}
										}
										current_outcome
										rank
										rankChange
										groupName
										played
										goalsFor
										goalsAgainst
										goalDiff
										win
										loss
										draw
										points
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getSeasonOnlyWithBrackets: gql`
		${PAIR_TEAM_FRAGMENT}
		query getSeasonOnlyWithBrackets($id: [String!]!) {
			stat {
				football {
					stat_season(id: $id) {
						id
						name
						shortName
						tournament {
							id
							tag {
								id
								hru
							}
						}
						stages {
							name
							type
							endDate
						}
						standingsBracketsStages {
							... on statSeasonBracket {
								countRounds
								name
								layers {
									round {
										name
									}
									nodes {
										id
										nextNodeID
										previousNodeID
										round {
											startDate
											endDate
											name
										}
										value {
											... on statMatchLite {
												id
												url
												matchStatus
												home {
													...pairTeam
												}
												away {
													...pairTeam
												}
												winner
												scheduledAt
												homeScore
												awayScore
												homePenaltyScore
												awayPenaltyScore
											}
											... on statTeamLite {
												__typename
												id
												name
												logo {
													url: main
													main
												}
											}
											... on statMatchSeriesLite {
												__typename
												pairWinner: winner {
													id
													name
												}
												items {
													url
													id
													matchStatus
													home {
														...pairTeam
													}
													away {
														...pairTeam
													}
													scheduledAt
													homeScore
													awayScore
													homePenaltyScore
													awayPenaltyScore
												}
											}
											... on statNone {
												__typename
												id
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getTopLeaguesTransferWindows: gql`
		fragment tournamentsTransfersWidgetFragment on statTournament {
			id
			name
			logo {
				webp(width: "40", height: "40", quality: 80)
			}
			transferWindows {
				year
				window
			}
		}

		query getTopLeaguesTransferWindows {
			stat {
				football {
					stat_topLeagues {
						...tournamentsTransfersWidgetFragment
					}
				}
			}
		}
	`,

	getLeagueTransfersForWindows: gql`
		fragment tournamentTransfersTeamData on statTeam {
			name
			url
			country {
				picture(productType: TRIBUNA, format: PNG) {
					main
				}
			}
		}

		query getLeagueTransfersForWindows(
			$id: ID!
			$year: Int!
			$window: statWindowTransfers!
		) {
			stat {
				football {
					stat_tournament(id: $id, source: GLOBAL) {
						id
						url
						tag {
							tabs
						}
						careers(
							input: {
								year: $year
								window: $window
								currentPage: 1
								pagesize: 10
								transfersType: ALL
								sort: DESC_PRICE
							}
						) {
							list {
								player {
									id
									url
									firstName
									lastName
									name
									fieldPositionName
									dateOfBirth
									avatar {
										main
									}
									nationality {
										name
										picture(productType: TRIBUNA, format: PNG) {
											main
										}
									}
								}
								team {
									...tournamentTransfersTeamData
								}
								teamOut {
									...tournamentTransfersTeamData
								}
								occupation
								transferValue
								transferCurrency
								startDate
							}
						}
					}
				}
			}
		}
	`,
	getPersonMainPageInfo: gql`
		${LIGHT_STAT_MATCH_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		${PERSON_MAIN_INFO_FRAGMENT}
		query getPersonMainPageInfo(
			$id: String!
			$locale: Language!
			$tagIdType: TagIdType!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...personMainInfoFragment
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statPlayer {
							id
							positions {
								localizedPosition
							}
							totalStat {
								goalsScored
								matchesPlayed
								cleanSheet
							}
							injuries(active: true) {
								active
							}
							lastMatches {
								match {
									...statMatchLight
								}
								team {
									id
								}
							}
							careers {
								trophies {
									tournament {
										competitionType
										id
										url
										name
									}
								}
								announcedDate
								transferType
								transferValue
								transferCurrency
								team {
									id
									type
									name
									url
									picture(productType: TRIBUNA, format: LOGO) {
										resize(width: "80px", height: "80px")
									}
									url
									tag {
										title {
											...localizedString
										}
									}
								}
								jerseyNumber
								active
							}
							countryOfBirth {
								name
							}
							currentNational {
								id
								url
								tag {
									tabs
								}
							}
							currentClub {
								teaser(last: 0, next: 2) {
									current {
										...statMatchLight
									}
									next {
										...statMatchLight
									}
								}
								tag {
									tabs
								}
								url
							}
						}
					}
				}
			}
		}
	`,
	getPersonMainInfo: gql`
		${PERSON_MAIN_INFO_FRAGMENT}
		query getPersonMainInfo(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
			$isActive: Boolean = true
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...personMainInfoFragment
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statPlayer {
							injuries(active: true) {
								active
							}
							careers(active: $isActive) {
								team {
									id
									name
									picture(productType: TRIBUNA, format: LOGO) {
										resize(width: "80px", height: "80px")
									}
									url
									tag {
										title {
											...localizedString
										}
									}
								}
								jerseyNumber
								active
							}
							positions {
								localizedPosition
							}
						}
					}
				}
			}
		}
	`,
	getPersonStatBySeason: gql`
		query getPersonStatBySeason(
			$id: String!
			$tagIdType: TagIdType!
			$season: String
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					statObject {
						... on statPlayer {
							fieldPositionName
							positions {
								localizedPosition
							}
							stat(teamSeason: $season) {
								matchesPlayed
								goalsScored
								goalsConceded
								cleanSheet
								assists
								yellowCards
								redCards
								season {
									shortName
									tournament {
										competitionType
									}
								}
								team {
									id
								}
							}
						}
					}
				}
			}
		}
	`,
	getPersonCareerPageInfo: gql`
		${PERSON_MAIN_INFO_FRAGMENT}
		${PERSON_CAREER_INFO}
		query getPersonCareerPageInfo(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...personMainInfoFragment
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statPlayer {
							fieldPosition
							careersGroup {
								club {
									startDate
									transferType
									transferCurrency
									transferValue
									team {
										id
										name
										type
										teamType
										logo {
											webp(width: "60", height: "60", quality: 100)
										}
										url
										...careerTeamInfo
									}
									...playerCareerData
								}
								national {
									team {
										...careerTeamInfo
									}
									...playerCareerData
								}
								coach {
									team {
										...careerTeamInfo
									}
									...coachCareerData
								}
							}
							playerTeamSeasonsForStat {
								seasons {
									id
								}
							}
							positions {
								localizedPosition
							}
							injuries {
								type
								days
								games
								startDate
								endDate
								active
								team {
									url
									name
									picture(productType: TRIBUNA, format: LOGO) {
										webp
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getPersonStatPageInfo: gql`
		${PERSON_MAIN_INFO_FRAGMENT}
		query getPersonStatPageInfo(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...personMainInfoFragment
					statObject {
						... on statPlayer {
							injuries(active: true) {
								active
							}
							careers(active: true) {
								team {
									id
									name
									picture(productType: TRIBUNA, format: LOGO) {
										resize(width: "80px", height: "80px")
									}
									url
									tag {
										title {
											...localizedString
										}
									}
								}
								jerseyNumber
								active
							}

							currentClub {
								homeTournament {
									nearestSeason {
										tournament {
											id
										}
									}
								}
							}

							positions {
								localizedPosition
							}

							playerTeamSeasonsForStat {
								id
								name
								seasons {
									id
									tournament {
										competitionFormat
										id
										tag {
											hru
											title {
												defaultValue(language: $locale)
											}
											hruList
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getPersonCurrentSeasonMatchesData: gql`
		${PLAYER_STAT_MATCH_DATA_FRAGMENT}
		query getPersonCurrentSeasonMatchesData(
			$id: ID!
			$source: statSourceList = GLOBAL
			$seasonIds: [String!]
		) {
			stat {
				football {
					stat_player(id: $id, source: $source) {
						id
						matches(input: { seasonIds: $seasonIds }) {
							isNotInSquad
							isOnTheBench
							match {
								...playerStatMatchData_info
							}
							stat {
								minutesPlayed
								penaltyGoalsScored
								goalsScored
								goalsConceded
								cleanSheet
								assists
								yellowCards
								yellowRedCards
								redCards
							}
						}
					}
				}
			}
		}
	`,

	getPlayerMatchDetailedStat: gql`
		query getPlayerMatchDetailedStat(
			$personIdStat: ID!
			$matchId: ID!
			$source: statSourceList
		) {
			stat {
				football {
					stat_match(id: $matchId, source: $source) {
						id
						url
						statListBlocks(playerID: $personIdStat) {
							id
							logo {
								webp(height: "40", width: "40", quality: 100)
							}
							stat {
								type
								name
								value
							}
						}
					}
				}
			}
		}
	`,
	getPlayerSeasons: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${TAG_TOURNAMENT_EXTRA_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		query getPlayerSeasons($id: ID!) {
			stat {
				football {
					stat_player(id: $id, source: UA_TAG) {
						id
						careers {
							active
							team {
								id
								type
								homeTournament {
									id
								}
							}
						}
						playerTeamSeasonsForStat {
							name
							startDate
							endDate
							seasons {
								id
								tournament {
									id
									name
									tag {
										id
										hru
										type
										hruList
										logo {
											url
										}
										title {
											...localizedString
										}
										extra {
											...tagTournamentExtra
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getPlayerTournamentMatches: gql`
		query getPlayerTournamentMatches($personId: ID!, $seasonId: ID!) {
			stat {
				football {
					stat_player(id: $personId, source: UA_TAG) {
						id
						type
						fieldPosition
						matches(input: { seasonId: $seasonId }) {
							match {
								id
								hru
								scheduledAt
								hadPenalties
								matchStatus
								url
								coverageLevel
								home {
									score
									penaltyScore
									team {
										name
										logo {
											webp(width: "40", height: "40", quality: 80)
										}
									}
								}
								away {
									score
									penaltyScore
									team {
										name
										logo {
											webp(width: "40", height: "40", quality: 80)
										}
									}
								}
							}
							stat {
								minutesPlayed
								penaltyGoalsScored
								goalsScored
								goalsConceded
								assists
								yellowCards
								yellowRedCards
								redCards
							}
						}
					}
				}
			}
		}
	`,
	getTeamTagSeasons: gql`
		${TAG_FRAGMENT}
		${TAG_TEAM_EXTRA_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		query getTeamTagSeasons(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTeam {
							id
							name
							tag {
								id
								hru
							}
							logo {
								main
							}
							roster(occupation: MANAGER) {
								role
								career {
									player {
										lastName
										url
									}
								}
							}
							homeTournament {
								id
								url
								name
								currentSeason {
									id
									shortName
								}
								nearestSeason {
									shortName
								}
								tag {
									id
									hru
									title {
										...localizedString
									}
								}
							}
							teamSeasonsFixtures {
								name
								id
								seasons {
									id
									shortName
									tournament {
										url
										name
										id
										tag {
											hru
											hruList
										}
									}
								}
							}
							venue {
								name
								country {
									name
								}
								tag {
									title {
										...localizedString
									}
								}
								capacity
								url
							}
							lastFive {
								match {
									id
									hru
									url
								}
								barHeight
								result
							}

							captain {
								lastName
								url
							}
							country {
								picture(productType: TRIBUNA, format: PNG) {
									main
								}
								name
							}
							city
							type
						}
					}
					extra {
						... on TagTeamExtra {
							country {
								name
							}
						}
					}
				}
			}
		}
	`,
	getCustomTabs: gql`
		${CUSTOM_TAB_FRAGMENT}
		query getCustomTabs($tagId: String!, $tabHru: String!, $lang: Language!) {
			tagQueries {
				getCustomTab(input: { tagId: $tagId, tabHru: $tabHru, lang: $lang }) {
					...CustomTab
				}
			}
		}
	`,
	getTagsRelatedNews: gql`
		query getTagRelatedNews(
			$tag: TagIDsInput!
			$page: Int = 1
			$pageSize: Int = 40
			$lang: Language!
			$status: DocumentStatus = PUBLISHED
		) {
			newsQueries {
				feed(
					input: {
						tag: $tag
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						language: $lang
						status: $status
					}
				) {
					items {
						__typename
						id
						hru
						title {
							defaultValue(language: $lang)
						}
						mainPhoto {
							url
						}
						isMainSection
						commentsCount
						publicationTime
						mainSport {
							id
							path
						}
						reactions {
							likeCount
						}
						languages
						application
					}
					paginationInfo {
						__typename
						... on PaginationByPage {
							currentPage
							lastPage
						}
					}
				}
			}
		}
	`,
	getLeagueStatTransfers: gql`
		fragment teamInfoForLeagueStat on statTeam {
			id
			url
			currentTournaments {
				id
				currentSeason {
					year
				}
			}
			name
			country {
				picture(format: PNG, productType: TRIBUNA) {
					main
				}
			}
		}
		query getLeagueStatTransfers($id: ID!, $input: filterTransfersInput!) {
			stat {
				football {
					stat_tournament(id: $id) {
						seasons {
							year
							startDate
							participants {
								id
								name
							}
						}
						careers(input: $input) {
							hasNextPage
							list {
								startDate
								occupation
								transferType
								transferValue
								transferCurrency
								player {
									id
									url
									avatar {
										main
									}
									fieldPositionName
									firstName
									lastName
									name
									dateOfBirth
									dateOfDeath
									nationality {
										picture(productType: TRIBUNA, format: PNG) {
											main
										}
									}
									positions {
										localizedPosition
									}
								}
								team {
									...teamInfoForLeagueStat
								}
								teamOut {
									...teamInfoForLeagueStat
								}
							}
						}
					}
				}
			}
		}
	`,

	getLeagueTeams: gql`
		query getLeagueTeams($hru: ID!) {
			stat {
				football {
					stat_tournament(id: $hru, source: EXTERNAL) {
						currentSeason {
							rankingTeamStat(input: { attribute: TOTAL_GOALS }) {
								items {
									team {
										id
										name
										logo {
											main
										}
										tag {
											hru
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,

	getTeamMainInfo: gql`
		${TAG_FRAGMENT}
		${TAG_CLUB_MAIN_INFO_STAT_OBJECT}
		query getTeamMainInfo($hru: String!, $locale: Language!) {
			tagQueries {
				get(input: { id: $hru, idType: HRU }) {
					...Tag
					tabs
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTeam {
							...tagClubMainInfoStatObject
						}
					}
				}
			}
		}
	`,
	getTeamTransfersPageInfo: gql`
		${TAG_FRAGMENT}
		${TAG_CLUB_MAIN_INFO_STAT_OBJECT}
		query getTeamTransfersPageInfo($hru: String!, $locale: Language) {
			tagQueries {
				get(input: { id: $hru, idType: HRU }) {
					...Tag
					tabs
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTeam {
							transferWindows {
								year
								window
							}
							...tagClubMainInfoStatObject
						}
					}
				}
			}
		}
	`,
	getTeamStatId: gql`
		query getTeamStatId($hru: String!) {
			tagQueries {
				get(input: { id: $hru, idType: HRU }) {
					statObject {
						... on statTeam {
							id
						}
					}
				}
			}
		}
	`,
	getTeamMainAndAboutTeamWidgetInfo: gql`
		${TAG_FRAGMENT}
		${TAG_STAT_MATCH_FRAGMENT}
		${TAG_CLUB_MAIN_INFO_STAT_OBJECT_WITHOUT_ROSTER}
		${RANKING_PLAYER_STAT_FRAGMENT}
		query getTeamMainAndAboutTeamWidgetInfo(
			$hru: String!
			$statTeamId: [String!]
			$locale: Language
		) {
			tagQueries {
				get(input: { id: $hru, idType: HRU }) {
					...Tag
					tabs
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTeam {
							...tagClubMainInfoStatObjectWithoutRoster
							roster {
								role
								career {
									player {
										currentNational {
											teamType
											id
										}
										lastName
										url
										injuries(active: true) {
											active
										}
									}
								}
							}
							rosterAverageAge {
								years
								months
							}
							legionnaires {
								player {
									id
								}
							}
							founded
							currentTournaments {
								id
								url
								name
							}
							trophies {
								tournament {
									id
									url
									name
								}
							}
							teaser(last: 1, next: 2) {
								last {
									...statMatchForTag
								}
								next {
									...statMatchForTag
								}
								current {
									...statMatchForTag
								}
							}
							homeTournament {
								currentSeason {
									rankingPlayerStat(
										input: {
											limit: 3
											attribute: [
												TOTAL_GOALS
												TOTAL_ASSISTS
												TOTAL_DUELS_WON
												TOTAL_ATT_ASSIST
											]
											teams: $statTeamId
										}
									) {
										...RankingPlayerStatFragment
									}
									goalkeeperPlayerStat: rankingPlayerStat(
										input: {
											limit: 3
											attribute: [TOTAL_CLEAN_SHEET]
											amplua: [GOALKEEPER]
											teams: $statTeamId
										}
									) {
										...RankingPlayerStatFragment
									}
								}
							}
						}
					}
				}
				recommendationList {
					clubs {
						id
						statObject {
							... on statTeam {
								url
								name
							}
						}
					}
				}
			}
		}
	`,
	getTeamStatTransfers: gql`
		fragment teamInfoForTeamStat on statTeam {
			id
			url
			name
			country {
				picture(format: PNG, productType: TRIBUNA) {
					main
				}
			}
		}
		query getLeagueStatTransfers($id: ID!, $input: filterTeamTransfersInput!) {
			stat {
				football {
					stat_team(id: $id) {
						careers(input: $input) {
							hasNextPage
							list {
								occupation
								transferType
								transferValue
								transferCurrency
								startDate
								player {
									id
									url
									avatar {
										main
									}
									fieldPositionName
									firstName
									lastName
									name
									dateOfBirth
									dateOfDeath
									nationality {
										picture(productType: TRIBUNA, format: PNG) {
											main
										}
									}
									positions {
										localizedPosition
									}
								}
								team {
									...teamInfoForTeamStat
								}
								teamOut {
									...teamInfoForTeamStat
								}
							}
						}
					}
				}
			}
		}
	`,
	getTeamStatStandings: gql`
		fragment season on statSeason {
			id
			name
			shortName
			tournament {
				id
				name
				tag {
					hru
					id
					hruList
				}
			}
		}
		query getTeamStatStandings($id: ID!, $source: statSourceList) {
			stat {
				football {
					stat_team(id: $id, source: $source) {
						id
						name
						logo {
							main
						}
						lastFive {
							result
							barHeight
						}
						homeTournamentTeamSeasonRaw {
							name
						}
						homeTournament {
							id
							currentSeason {
								id
							}
						}
						teamSeasonsRaw(showFriendlies: false, showWithTree: true) {
							name
							seasons {
								...season
							}
						}
						type
					}
				}
			}
		}
	`,
	getSeason: gql`
		fragment seasonTeamStandingsFragment on statTeamStandingLine {
			team {
				id
				name
				url
				coverage {
					matches
				}
				logo {
					main
					resize(width: "32px", height: "32px")
				}
				lastFive: lastFiveSeason(season: $season) {
					match {
						id
						hru
						url
					}
					pointsDiff
					barHeight
					result
				}
			}
			current_outcome
			rank
			rankChange
			groupName
			played
			goalsFor
			goalsAgainst
			goalDiff
			win
			loss
			draw
			points
		}

		fragment pairTeam on statTeamLite {
			id
			name
			logo {
				url: main
				main
			}
		}
		query getSeason($id: [String!]!, $season: ID!) {
			stat {
				football {
					stat_season(id: $id) {
						id
						name
						shortName
						tournament {
							id
							name
							tag {
								id
								hru
							}
						}
						standingsBracketsStages {
							... on statSeasonBracket {
								countRounds
								name
								layers {
									round {
										name
									}
									nodes {
										id
										nextNodeID
										previousNodeID
										round {
											startDate
											endDate
											name
										}
										value {
											... on statMatchLite {
												id
												url
												matchStatus
												home {
													...pairTeam
												}
												away {
													...pairTeam
												}
												winner
												scheduledAt
												homeScore
												awayScore
												homePenaltyScore
												awayPenaltyScore
											}
											... on statTeamLite {
												__typename
												id
												name
												logo {
													url: main
													main
												}
											}
											... on statMatchSeriesLite {
												__typename
												pairWinner: winner {
													id
													name
												}
												items {
													url
													id
													matchStatus
													home {
														...pairTeam
													}
													away {
														...pairTeam
													}
													scheduledAt
													homeScore
													awayScore
													homePenaltyScore
													awayPenaltyScore
												}
											}
											... on statNone {
												__typename
												id
											}
										}
									}
								}
							}
							... on statTRound {
								includeStandings
								name
								teamStanding {
									total {
										...seasonTeamStandingsFragment
									}
									home {
										team {
											id
											name
											url
											coverage {
												matches
											}
											logo {
												main
												resize(width: "32px", height: "32px")
											}
											lastFive: lastFiveSeason(
												season: $season
												qualifier: HOME
											) {
												match {
													id
													url
												}
												pointsDiff
												barHeight
												result
											}
										}
										current_outcome
										rank
										rankChange
										groupName
										played
										goalsFor
										goalsAgainst
										goalDiff
										win
										loss
										draw
										points
									}
									away {
										team {
											id
											name
											url
											coverage {
												matches
											}
											logo {
												main
												resize(width: "32px", height: "32px")
											}
											lastFive: lastFiveSeason(
												season: $season
												qualifier: AWAY
											) {
												match {
													id
													url
												}
												pointsDiff
												barHeight
												result
											}
										}
										current_outcome
										rank
										rankChange
										groupName
										played
										goalsFor
										goalsAgainst
										goalDiff
										win
										loss
										draw
										points
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getTeamsSeasons: gql`
		query getTeamsSeasons($id: String!, $tagIdType: TagIdType!) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					statObject {
						... on statTeam {
							type
							teamSeasons(onlyHomeTournament: true, onlyWithRoster: true) {
								seasons {
									id
									name
									shortName
									startDate
									endDate
								}
							}
							rosterYears {
								id
								year
								shortName
								endDate
							}
						}
					}
				}
			}
		}
	`,
	getTeamsSquad: gql`
		${TAG_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		${TAG_TEAM_EXTRA_FRAGMENT}
		query getTeamsSquad(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
			$seasonId: ID
			$year: Int
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTeam {
							id
							name
							tag {
								id
								hru
								extra {
									...tagTeamExtra
								}
							}
							logo {
								main
							}
							teamSeasons {
								name
							}
							teamSeasonsRaw(
								onlyWithRoster: true
								onlyWithPlayerSeasonStat: true
								hasPlayed: true
							) {
								name
								seasons {
									id
								}
							}
							lastFive {
								result
								match {
									url
								}
							}
							type
							venue {
								name
								country {
									name
								}
								capacity
								tag {
									title {
										...localizedString
									}
								}
								url
							}
							country {
								picture(productType: TRIBUNA, format: PNG) {
									main
								}
								name
							}
							city
							homeTournament {
								id
								url
								name
								currentSeason {
									id
									shortName
								}
								tag {
									id
									hru
									title {
										...localizedString
									}
								}
							}
							captain {
								id
								firstName
								lastName
								url
								dateOfBirth
								avatar {
									main
								}
								nationality {
									name
									picture(productType: TRIBUNA, format: PNG) {
										main
									}
								}
							}
							roster(occupation: MANAGER) {
								role
								career {
									player {
										lastName
										url
										id
										tag {
											id
											hru
											extra {
												...tagPersonExtra
											}
										}
										name
										firstName
									}
								}
							}
							rosterSeason: roster(season: $seasonId, year: $year) {
								role
								activeInjuries {
									active
								}
								career {
									jerseyNumber
									yearsInTeam
									isNew
									hasLeftThisSeason
									isNewThisSeason
									team {
										id
										name
										url
									}
									player {
										trophiesCount(beforeSeasonID: $seasonId, beforeYear: $year)
										id
										firstName
										lastName
										type
										lastFieldPosition
										fieldPositionName
										fieldPosition
										url
										avatar {
											main
										}
										height
										weight
										dateOfBirth
										currentClub {
											name
											url
										}
										nationality {
											name
											picture(productType: TRIBUNA, format: PNG) {
												main
											}
										}
										positions {
											abbreviation
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getTeamSquadStatSeasonsAndInfo: gql`
		${TAG_FRAGMENT}
		${TAG_CLUB_MAIN_INFO_STAT_OBJECT}
		query getTeamSquadSeasonsAndInfo(
			$id: String!
			$tagIdType: TagIdType!
			$locale: Language!
		) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					...Tag
					tabs(lang: $locale)
					customTabs(lang: $locale) {
						id
						name
						hru
						redirect
					}
					statObject {
						... on statTeam {
							...tagClubMainInfoStatObject
							homeTournament {
								currentSeason {
									id
								}
							}
							teamSeasonsRaw(
								onlyWithRoster: true
								onlyWithPlayerSeasonStat: true
								hasPlayed: true
							) {
								name
								seasons {
									id
									shortName
									endDate
									tournament {
										id
										name
										tag {
											id
											hru
											hruList
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getTeamSquadStat: gql`
		${BEST_IN_TEAM_SEASON_WIDGET_FRAGMENT}
		query getTeamSquadStat(
			$statSeasonID: [String!]!
			$statTeamID: [String!]!
			$teamID: ID!
			$idTeamString: String!
			$seasonID: ID!
		) {
			stat {
				football {
					stat_season(id: $statSeasonID) {
						...BestInSeasonWidgetFragment
					}
					stat_team(id: $teamID) {
						type
						roster(season: $seasonID, occupation: PLAYER, hasPlayed: true) {
							role
							career {
								isNew
								hasLeftThisSeason
								isNewThisSeason
								jerseyNumber
								active
								player {
									id
									firstName
									lastName
									url
									type
									fieldPosition
									lastFieldPosition
									avatar {
										main
									}
									injuries(active: true) {
										active
									}
									nationality {
										picture(productType: TRIBUNA, format: PNG) {
											main
										}
									}
									currentClub {
										name
										url
									}
									positions {
										abbreviation
									}
									stat(idSeason: $seasonID, idTeam: $idTeamString) {
										matchesPlayed
										goalsConceded
										goalsScored
										cleanSheet
										saves
										assists
										redCards
										yellowCards
										percentageDuelsWon
										totalAttAssists
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	getBestPlayersByMetric: gql`
		${RANKING_PLAYER_STAT_FRAGMENT}
		query getBestPlayersByMetric(
			$statSeasonID: [String!]!
			$teamID: [String!]
			$attribute: [statRankingAttribute!]
			$amplua: [Position!]
		) {
			stat {
				football {
					stat_season(id: $statSeasonID) {
						rankingPlayerStat(
							input: { teams: $teamID, attribute: $attribute, amplua: $amplua }
						) {
							...RankingPlayerStatFragment
						}
					}
				}
			}
		}
	`,
	getBestPlayersByMetricAveragePerGame: gql`
		${RANKING_PLAYER_STAT_FRAGMENT}
		query getBestPlayersByMetricAveragePerGame(
			$statSeasonID: [String!]!
			$teamID: [String!]
			$attribute: [statRankingAttribute!]
			$amplua: [Position!]
		) {
			stat {
				football {
					stat_season(id: $statSeasonID) {
						rankingPlayerStatPerGame(
							input: { teams: $teamID, attribute: $attribute, amplua: $amplua }
						) {
							...RankingPlayerStatFragment
						}
					}
				}
			}
		}
	`,
	getLeagueSeasons: gql`
		query getLeagueSeasons($id: String!, $tagIdType: TagIdType!) {
			tagQueries {
				get(input: { id: $id, idType: $tagIdType }) {
					statObject {
						... on statTournament {
							id
							type
							nearestSeason {
								id
								shortName
								name
							}
							seasonsWithStat {
								id
								shortName
								name
							}
						}
					}
				}
			}
		}
	`,
	getBestTeams: gql`
		${BEST_TEAMS_IN_SEASON_FRAGMENT}
		query getBestTeams($statSeasonID: [String!]!) {
			stat {
				football {
					stat_season(id: $statSeasonID) {
						...bestTeamsInSeasonFragment
					}
				}
			}
		}
	`,
	getBestTeamsByMetric: gql`
		${RANKING_TEAM_STAT_FRAGMENT}
		query getBestTeamsByMetric(
			$statSeasonID: [String!]!
			$attribute: [statRankingTeamAttribute!]
		) {
			stat {
				football {
					stat_season(id: $statSeasonID) {
						rankingTeamStat(input: { attribute: $attribute }) {
							...rankingTeamStatFragment
						}
					}
				}
			}
		}
	`,
	getBestTeamsByMetricAveragePerGame: gql`
		${RANKING_TEAM_STAT_FRAGMENT}
		query getBestTeamsByMetricAveragePerGame(
			$statSeasonID: [String!]!
			$attribute: [statRankingTeamAttribute!]
		) {
			stat {
				football {
					stat_season(id: $statSeasonID) {
						rankingTeamStatPerGame(input: { attribute: $attribute }) {
							...rankingTeamStatFragment
						}
					}
				}
			}
		}
	`,
	getBestPlayers: gql`
		${BEST_IN_TEAM_SEASON_PAGE_FRAGMENT}
		query getBestPlayers($statSeasonID: [String!]!, $teamID: [String!]) {
			stat {
				football {
					stat_season(id: $statSeasonID) {
						...BestInSeasonPageFragment
					}
				}
			}
		}
	`,

	searchByTagName: gql`
		${LOCALIZED_STRING_FRAGMENT}
		query searchByTagName($pattern: String!, $sort: TagSearchSort = NONE) {
			tagQueries {
				search(input: { sort: $sort, pattern: $pattern }) {
					id
					hru
					type
					extra {
						... on TagTeamExtra {
							type
						}
					}
					statObject {
						... on statTeam {
							type
						}
					}
					logo {
						url
					}
					title {
						...localizedString
					}
					sport {
						name {
							...localizedString
						}
						path
					}
				}
			}
		}
	`,
	getContentForMainPage: gql`
		${POST_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		${POLL_DESCRIPTION_FRAGMENT}
		query getContentForMainPage(
			$page: Int!
			$pageSize: Int!
			$tag: TagIDsInput
			$languages: [Language!]!
			$status: [DocumentStatus!] = [PUBLISHED]
			$lang: Language
			$contentTypes: [ContentType!]
			$sport: [SportID!]
			$main: Boolean
			$mainInSection: Boolean
		) {
			feedQueries {
				feedPAL(
					input: {
						tag: $tag
						newsLanguages: $languages
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						status: $status
						contentTypes: $contentTypes
						sport: $sport
						main: $main
						mainInSection: $mainInSection
					}
				) {
					items {
						__typename
						... on Post {
							...Post
						}
						... on News {
							id
							hru
							commentsCount
							isMainSection
							url(input: { useAvailableLang: true })
							newsTitle: title {
								defaultValue(language: $lang)
							}
							label
							newsAuthor: author {
								user {
									id
									name
								}
							}
							mainPhoto {
								url
								webp(width: 250, height: 140, quality: 80)
							}
							mainSport {
								path
								name {
									defaultValue(language: $lang)
								}
								id
							}
							publicationTime
						}
						... on CustomPoll {
							id
							status
							isVoted
							totalVotes
							commentsCount
							mainSport {
								id
								path
								name {
									defaultValue(language: $lang)
								}
							}
							languages
							sports {
								id
								path
								name {
									...localizedString
								}
							}
							configurationStatus
							pollType
							authPollType
							hru
							link
							votingStatus
							publicationTime
							modifiedTime
							endsAt
							title {
								defaultValue(language: $lang)
							}
							structuredDescription {
								defaultValue(language: $lang) {
									...pollDescription
								}
							}
							options {
								... on CustomPollOption {
									optionID
									optionText {
										defaultValue(language: $lang)
									}
									pictureURL
									votes
									votesPercentage
									isChosen
								}
								... on TagPollOption {
									optionID
									tag {
										id
										title {
											defaultValue(language: $lang)
										}
										logo {
											url
										}
										type
									}
									votes
									votesPercentage
									isChosen
								}
							}
						}
					}
					paginationInfo {
						... on PaginationByPage {
							currentPage
							lastPage
						}
					}
				}
			}
		}
	`,

	getIgamingContentPageInfo: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_SB_FRAGMENT}
		${MULTI_LANG_STRING}
		query getIgamingContentPageInfo($id: String!, $locale: Language!) {
			tagQueries {
				get(input: { id: $id, idType: HRU }) {
					id
					hru
					type
					seoPages {
						type
						seo {
							h1 {
								...localizedString
							}
							title {
								...localizedString
							}
							description {
								...localizedString
							}
							canonical {
								...localizedString
							}
							synopsis {
								...localizedString
							}
							faqItems {
								title {
									...multiLangString
								}
								structuredBody {
									...LocalizedSB
								}
							}
							text {
								...LocalizedSB
							}
						}
					}
					tabs(lang: $locale)
				}
			}
		}
	`,

	getIgamingMainPageInfo: gql`
		query getIgamingContentPageInfo($id: String!, $locale: Language!) {
			tagQueries {
				get(input: { id: $id, idType: HRU }) {
					id
					hru
					type
					tabs(lang: $locale)
				}
			}
		}
	`,
};
